import React from "react";
import "./Footer.css";

function Footer(props) {
  return (
    <div>
      <footer className="footer-area section_gap container-ft">
        <div
          className="container container-ft2"
          style={{ display: "flex", color: "white", gap: "50px" }}
        >
          <div className="footer-content" style={{maxWidth: "60%"}}>
            Chào mừng bạn đến với EASYTESTING – nơi kiến thức và thực hành Kiểm
            thử Phần mềm hội tụ! Tại đây, chúng tôi không chỉ mang đến cho bạn
            những kiến thức chuyên sâu mà còn đến những trải nghiệm thực tế quý
            giá. Với đội ngũ giảng viên giàu kinh nghiệm và là những chuyên gia
            hàng đầu trong lĩnh vực kiểm thử phần mềm, chúng tôi cam kết giúp
            bạn phát triển kỹ năng kiểm thử và ứng dụng chúng một cách hiệu quả
            trong công việc. EASYTESTING tự hào là một môi trường học tập tích
            cực, nơi mà thực hành là trọng tâm, giúp bạn tự tin vươn tới những
            thành công trong sự nghiệp kiểm thử phần mềm. Hãy cùng chúng tôi,
            kiến tạo nên một tương lai rực rỡ!
            <div style={{ marginTop: "8px" }}>
              Đăng ký khoá học ngay: <a style={{cursor: "pointer"}} href="http://facebook.com/easytesting.vn">Facebook</a>
            </div>
          </div>
          <div
            style={{
              borderRadius: "8px",
              boxShadow: "rgba(149, 157, 165, 0.3) 0px 8px 24px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "20px",
              padding: "0 20px",
              color: "#808080"
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "10px"}}>
              <img
                width={24}
                height={24}
                style={{  }}
                src="	https://cdn-icons-png.flaticon.com/128/3849/3849887.png"
              />
              <a style={{  }} href="https://easytesting.vn/">
                https://easytesting.vn/
              </a>
            </div>
            <div style={{ display: "flex", alignItems: "", gap: "10px" }}>
              <img
                width={24}
                height={24}
                src="https://cdn-icons-png.flaticon.com/512/145/145802.png"
              />
              <a
                style={{  }}
                href="https://www.facebook.com/easytesting.vn"
              >
                https://www.facebook.com/ easytesting.vn
              </a>
            </div>
            <div
              style={{ display: "flex", justifyContent: "left", gap: "10px" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  fill="black"
                  d="M23,11a1,1,0,0,1-1-1,8.008,8.008,0,0,0-8-8,1,1,0,0,1,0-2A10.011,10.011,0,0,1,24,10,1,1,0,0,1,23,11Zm-3-1a6,6,0,0,0-6-6,1,1,0,1,0,0,2,4,4,0,0,1,4,4,1,1,0,0,0,2,0Zm2.183,12.164.91-1.049a3.1,3.1,0,0,0,0-4.377c-.031-.031-2.437-1.882-2.437-1.882a3.1,3.1,0,0,0-4.281.006l-1.906,1.606A12.784,12.784,0,0,1,7.537,9.524l1.6-1.9a3.1,3.1,0,0,0,.007-4.282S7.291.939,7.26.908A3.082,3.082,0,0,0,2.934.862l-1.15,1C-5.01,9.744,9.62,24.261,17.762,24A6.155,6.155,0,0,0,22.183,22.164Z"
                />
              </svg>
              0968.754.966
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-2 col-md-6 single-footer-widget">
              <h4>Top Products</h4>
              <ul>
                <li><a href="#">Managed Website</a></li>
                <li><a href="#">Manage Reputation</a></li>
                <li><a href="#">Power Tools</a></li>
                <li><a href="#">Marketing Service</a></li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 single-footer-widget">
              <h4>Quick Links</h4>
              <ul>
                <li><a href="#">Jobs</a></li>
                <li><a href="#">Brand Assets</a></li>
                <li><a href="#">Investor Relations</a></li>
                <li><a href="#">Terms of Service</a></li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 single-footer-widget">
              <h4>Features</h4>
              <ul>
                <li><a href="#">Jobs</a></li>
                <li><a href="#">Brand Assets</a></li>
                <li><a href="#">Investor Relations</a></li>
                <li><a href="#">Terms of Service</a></li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 single-footer-widget">
              <h4>Resources</h4>
              <ul>
                <li><a href="#">Guides</a></li>
                <li><a href="#">Research</a></li>
                <li><a href="#">Experts</a></li>
                <li><a href="#">Agencies</a></li>
              </ul>
            </div>

          </div> */}
        </div>
      </footer>
    </div>
  );
}

export default Footer;
